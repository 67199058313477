
import { defineComponent, PropType, toRefs, computed } from "vue";
import { someColors } from "@/utils";
import { ProcessItem } from "./../type";

export default defineComponent({
  name: "ListItem",
  emits: ["mark"],
  props: {
    data: {
      type: Object as PropType<ProcessItem>,
      required: true,
    },
    marked: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const { data } = toRefs(props);

    const title = data.value.processDefinitionName;

    const iconText = computed(() => {
      let res = title.match(/[A-Za-z0-9\u4E00-\u9FA5]/);
      return res ? res[0] : "OA";
    });
    const colorIndex = computed<number>(() => {
      const titleToCharAt = iconText.value?.charCodeAt(0).toString(4);
      return parseInt(titleToCharAt.charAt(titleToCharAt.length - 1));
    });

    return { someColors, colorIndex, iconText };
  },
});
