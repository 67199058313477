import { toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "process-list-item" }
const _hoisted_2 = { class: "item-content" }
const _hoisted_3 = { class: "item-main" }
const _hoisted_4 = { class: "item-title" }
const _hoisted_5 = { class: "item-title-text" }
const _hoisted_6 = { class: "item-dec" }
const _hoisted_7 = ["clstag"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_van_icon = _resolveComponent("van-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: "item-avatar",
      style: _normalizeStyle({
        backgroundImage: `linear-gradient(to right bottom, ${_ctx.someColors[_ctx.colorIndex][0]}, ${_ctx.someColors[_ctx.colorIndex][1]})`,
      })
    }, _toDisplayString(_ctx.iconText), 5),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", _hoisted_5, _toDisplayString(_ctx.data.processDefinitionName), 1)
        ]),
        _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.data.processTypeName), 1)
      ]),
      _createElementVNode("div", {
        class: "item-operation",
        onTouchend: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (_ctx.$emit('mark', !_ctx.marked)), ["stop"])),
        clstag: `h|keycount|process_list_1648196102634|${_ctx.marked ? '2' : '1'}`
      }, [
        _createVNode(_component_van_icon, {
          name: _ctx.marked ? 'star' : 'star-o',
          size: "20",
          color: "#ffb416"
        }, null, 8, ["name"])
      ], 40, _hoisted_7)
    ])
  ]))
}