import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createBlock as _createBlock, withCtx as _withCtx, normalizeStyle as _normalizeStyle, createTextVNode as _createTextVNode, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "process-center-wrapper" }
const _hoisted_2 = { class: "process-center-search" }
const _hoisted_3 = { class: "processType-title" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PageHeader = _resolveComponent("PageHeader")!
  const _component_van_search = _resolveComponent("van-search")!
  const _component_Empty = _resolveComponent("Empty")!
  const _component_list_item = _resolveComponent("list-item")!
  const _component_van_collapse_item = _resolveComponent("van-collapse-item")!
  const _component_van_collapse = _resolveComponent("van-collapse")!
  const _component_van_pull_refresh = _resolveComponent("van-pull-refresh")!
  const _component_van_loading = _resolveComponent("van-loading")!
  const _component_SearchList = _resolveComponent("SearchList")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_PageHeader, {
        title: "流程申请",
        isFixed: false
      }),
      _createVNode(_component_van_search, {
        placeholder: "搜索",
        onFocus: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showSearch = true))
      })
    ]),
    _createElementVNode("div", {
      class: "process-list-wrapper",
      style: _normalizeStyle({
        paddingTop: _ctx.paddingTop + 8 + 'px',
      })
    }, [
      _createVNode(_component_van_pull_refresh, {
        style: _normalizeStyle({
          height: `calc(100vh - ${_ctx.paddingTop + 20 + 'px'}) `,
          overflow: 'auto',
          '-webkit-overflow-scrolling': 'touch',
        }),
        modelValue: _ctx.refreshing,
        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.refreshing) = $event)),
        onRefresh: _ctx.onRefresh
      }, {
        default: _withCtx(() => [
          _withDirectives(_createVNode(_component_Empty, null, null, 512), [
            [_vShow, !_ctx.loading && _ctx.typeList.length === 0]
          ]),
          _createVNode(_component_van_collapse, {
            modelValue: _ctx.activeIds,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.activeIds) = $event)),
            border: false
          }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.typeList, (sort, index) => {
                return (_openBlock(), _createBlock(_component_van_collapse_item, {
                  key: index,
                  name: sort.processTypeId,
                  border: false,
                  style: {"margin-bottom":"8px"}
                }, {
                  title: _withCtx(() => [
                    _createElementVNode("div", _hoisted_3, _toDisplayString(sort.processTypeName) + "（" + _toDisplayString(sort.num) + "） ", 1)
                  ]),
                  default: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(sort.list, (item, i) => {
                      return (_openBlock(), _createBlock(_component_list_item, {
                        key: i,
                        data: item,
                        marked: !!item.processDefinitionMark,
                        onTouchstart: _ctx.touchstart,
                        onTouchmove: _ctx.touchmove,
                        onTouchend: () => _ctx.touchend(item),
                        onMark: 
                (marked) => _ctx.handleMark({ sort, index, listIndex: i, marked })
              
                      }, null, 8, ["data", "marked", "onTouchstart", "onTouchmove", "onTouchend", "onMark"]))
                    }), 128))
                  ]),
                  _: 2
                }, 1032, ["name"]))
              }), 128))
            ]),
            _: 1
          }, 8, ["modelValue"])
        ]),
        _: 1
      }, 8, ["style", "modelValue", "onRefresh"])
    ], 4),
    (_ctx.loading)
      ? (_openBlock(), _createBlock(_component_van_loading, {
          key: 0,
          class: "card-loading",
          color: "var(--van-danger-color)",
          vertical: ""
        }, {
          default: _withCtx(() => [
            _createTextVNode("加载中")
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    (_ctx.showSearch)
      ? (_openBlock(), _createBlock(_component_SearchList, {
          key: 1,
          class: "searchBox",
          onCancel: _cache[3] || (_cache[3] = ($event: any) => (_ctx.showSearch = false)),
          style: _normalizeStyle({
        height: `calc(100vh - ${_ctx.paddingTop - 54 + 'px'})`,
        top: _ctx.paddingTop - 54 + 'px',
      })
        }, null, 8, ["style"]))
      : _createCommentVNode("", true)
  ]))
}