
import { getDeviceSomeInfo } from "@/utils";
import { defineComponent } from "vue";

export default defineComponent({
  name: "TaskFilter",
  emits: ["filter", "update:modelValue"],
  props: {
    title: {
      type: String,
    },
    isFixed: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  setup() {
    const { safeTop } = getDeviceSomeInfo();
    const paddingTop = Math.max(safeTop - 46, 0);
    const closePage = () => {
      window.jme?.browser?.goback?.();
    };
    const openMore = () => {
      window.jme?.share?.showCustomMenu?.({
        appId: process.env.REACT_APP_APP_KEY || "",
        shareData: {} as any,
        typeList: [],
      });
    };
    return {
      closePage,
      openMore,
      paddingTop,
    };
  },
});
