
import { defineComponent, ref, reactive, watch } from "vue";
import { Toast } from "vant";
import fetchApi from "@/api";
import { eventBus, getDeviceSomeInfo } from "@/utils";
import { ProcessItem } from "./../type";
import SearchList from "./SearchList.vue";
import { ProcessType } from "./type";
import ListItem from "./Item.vue";
import PageHeader from "@/components/PageHeader.vue";

export default defineComponent({
  name: "ProcessList",
  components: { SearchList, ListItem, PageHeader },
  setup() {
    const { safeTop } = getDeviceSomeInfo();
    const paddingTop = Math.max(safeTop - 46, 0) + 44 + 54;
    const showSearch = ref(false);
    const typeList = reactive<ProcessType[]>([]);
    const activeIds = ref<string[]>([]);
    const refreshing = ref(false);
    const loading = ref(false);
    const flag = ref(1);
    // const markList = ref<string[]>([]);

    const fetchList = (key: string) => {
      loading.value = true;
      const index = typeList.findIndex((item) => item.processTypeId === key);
      fetchApi
        .getProcessList()
        .data({ processTypeParentId: key })
        .send("POST")
        .then((res) => {
          typeList.splice(index, 1, {
            ...typeList[index],
            list: res.data || [],
          });
          loading.value = false;
        })
        .catch(() => {
          loading.value = false;
        });
    };

    watch(
      activeIds,
      (val, oldVal) => {
        if (oldVal.length < val.length) {
          const key = val.find((key) => oldVal.indexOf(key) === -1);
          const index = typeList.findIndex(
            (item) => item.processTypeId === key
          );
          if (key && typeList[index].list.length === 0) {
            fetchList(key);
          }
        }
      },
      { deep: true }
    );

    const onRefresh = () => {
      if (loading.value) {
        refreshing.value = false;
        return;
      }
      refreshing.value = true;
      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      loading.value = true;
      onLoad();
    };

    const onLoad = async () => {
      if (refreshing.value) {
        typeList.length = 0;
        activeIds.value = [];
        refreshing.value = false;
      }
      loading.value = true;
      fetchApi
        .getApplyNum()
        .data({})
        .send("POST")
        .then((res) => {
          const data = res.data.filter((item: ProcessType) => item.num);
          typeList.splice(
            0,
            0,
            ...data.map((item: ProcessType) => ({ ...item, list: [] }))
          );
          if (data.length === 1) {
            const [first] = data;
            activeIds.value = [...activeIds.value, first.processTypeId];
          }
          loading.value = false;
        })
        .catch(() => {
          loading.value = false;
        });
    };

    onLoad();

    eventBus.on("fixMark", (node) => {
      let typeIndex = -1;
      let listIndex = -1;
      let list: ProcessItem[] = [];
      for (let i = 0; i < typeList.length; i++) {
        let flag = false;
        const typeItem = typeList[i];
        if (!(typeItem.list && typeItem.list.length > 0)) {
          continue;
        }
        list = typeItem.list.map((item, index) => {
          if (item.id === (node as unknown as ProcessItem).id) {
            typeIndex = i;
            listIndex = index;
            flag = true;
            return node as unknown as ProcessItem;
          }
          return item;
        });
        if (flag) {
          break;
        }
      }
      if (listIndex > -1 && typeIndex > -1) {
        typeList.splice(typeIndex, 1, { ...typeList[listIndex], list });
      }
    });

    const touchstart = () => {
      flag.value = 1;
    };
    const touchmove = () => {
      flag.value = 0;
    };
    const touchend = (item: ProcessItem) => {
      if (flag.value === 1) {
        window.open(item.mobileApplyLink);
      }
    };
    const handleMark = (opt: {
      sort: ProcessType;
      index: number;
      listIndex: number;
      marked: Boolean;
    }) => {
      const { sort, index, listIndex, marked } = opt;
      const item = sort.list[listIndex];
      const fetch = marked ? fetchApi.mark : fetchApi.mark_del;
      const message = marked ? "收藏成功" : "取消收藏成功";
      const params = marked
        ? { processDefinitionId: item.id }
        : { id: item.processDefinitionMark?.id };

      fetch()
        .data(params)
        .send("POST")
        .then((res) => {
          Toast.success(message);
          let node: ProcessItem;
          if (marked) {
            node = {
              ...item,
              processDefinitionMark: {
                id: Number(res.data || 0),
                processDefinitionId: item.id,
                processDefinitionName: "",
                mobileApplyLink: "",
              },
            };
          } else {
            node = { ...item };
            delete node.processDefinitionMark;
          }
          typeList.splice(index, 1, {
            ...sort,
            list: sort.list.map((o) => (o.id === item.id ? node : o)),
          });
        });
    };

    return {
      showSearch,
      typeList,
      activeIds,
      refreshing,
      loading,
      onRefresh,
      touchstart,
      touchmove,
      touchend,
      handleMark,
      paddingTop,
    };
  },
});
