
import { defineComponent, ref } from "vue";
import { Toast } from "vant";
import fetchApi from "@/api";
import { eventBus } from "@/utils";
import { ProcessItem } from "./../type";
import ListItem from "./Item.vue";

export default defineComponent({
  name: "SearchValue",
  emits: ["cancel"],
  components: { ListItem },
  setup(props, context) {
    const value = ref("");
    const list = ref<ProcessItem[]>([]);
    const loading = ref(false);
    const refreshing = ref(false);
    const flag = ref(1);

    const onLoad = () => {
      if (!value.value) {
        loading.value = false;
        list.value = [];
        return;
      }
      if (refreshing.value) {
        list.value = [];
        refreshing.value = false;
      }
      loading.value = true;
      return fetchApi
        .getProcessList()
        .data({ searchKey: value.value })
        .send("POST")
        .then((res) => {
          list.value = [...list.value, ...(res.data || [])];
          loading.value = false;
        })
        .catch(() => {
          loading.value = false;
        });
    };
    const onSearch = () => {
      // 清空列表数据
      refreshing.value = true;

      // 重新加载数据
      // 将 loading 设置为 true，表示处于加载状态
      loading.value = true;
      onLoad();
    };
    const onCancel = () => {
      value.value = "";
      loading.value = false;
      list.value = [];
      context.emit("cancel");
    };

    const touchstart = () => {
      flag.value = 1;
    };
    const touchmove = () => {
      flag.value = 0;
    };
    const touchend = (item: ProcessItem) => {
      window.open(item.mobileApplyLink);
    };
    const handleMark = (item: ProcessItem, index: number, marked: boolean) => {
      const fetch = marked ? fetchApi.mark : fetchApi.mark_del;
      const message = marked ? "收藏成功" : "取消收藏成功";
      const params = marked
        ? { processDefinitionId: item.id }
        : { id: item.processDefinitionMark?.id };

      fetch()
        .data(params)
        .send("POST")
        .then((res) => {
          Toast.success(message);
          let node: ProcessItem;
          if (marked) {
            node = {
              ...item,
              processDefinitionMark: {
                id: Number(res.data || 0),
                processDefinitionId: item.id,
                processDefinitionName: "",
                mobileApplyLink: "",
              },
            };
          } else {
            node = { ...item };
            delete node.processDefinitionMark;
          }
          list.value.splice(index, 1, node);
          eventBus.emit("fixMark", node);
        });
    };

    return {
      value,
      list,
      loading,
      onLoad,
      onSearch,
      onCancel,
      touchstart,
      touchmove,
      touchend,
      handleMark,
    };
  },
});
