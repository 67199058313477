import { resolveComponent as _resolveComponent, resolveDirective as _resolveDirective, createVNode as _createVNode, withDirectives as _withDirectives, createElementVNode as _createElementVNode, vShow as _vShow, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "proc-search" }
const _hoisted_2 = { action: "" }
const _hoisted_3 = { class: "proc-search-list" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_van_search = _resolveComponent("van-search")!
  const _component_Empty = _resolveComponent("Empty")!
  const _component_list_item = _resolveComponent("list-item")!
  const _directive_focus = _resolveDirective("focus")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("form", _hoisted_2, [
      _withDirectives(_createVNode(_component_van_search, {
        modelValue: _ctx.value,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.value) = $event)),
        "show-action": "",
        placeholder: "请输入流程名称",
        onSearch: _ctx.onSearch,
        onCancel: _ctx.onCancel
      }, null, 8, ["modelValue", "onSearch", "onCancel"]), [
        [_directive_focus]
      ])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _withDirectives(_createVNode(_component_Empty, null, null, 512), [
        [_vShow, !_ctx.loading && _ctx.list.length === 0]
      ]),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.list, (item, i) => {
        return (_openBlock(), _createBlock(_component_list_item, {
          key: i,
          data: item,
          marked: !!item.processDefinitionMark,
          onTouchstart: _ctx.touchstart,
          onTouchmove: _ctx.touchmove,
          onTouchend: () => _ctx.touchend(item),
          onMark: (marked) => _ctx.handleMark(item, i, marked)
        }, null, 8, ["data", "marked", "onTouchstart", "onTouchmove", "onTouchend", "onMark"]))
      }), 128))
    ])
  ]))
}